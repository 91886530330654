import React from 'react'
import { PageProps } from 'gatsby'

import * as styles from '../../../styles/pages/solutionsPage.module.scss'

import { SEO } from '../../../components/SEO'
import { SuccessCases } from '../../../components/SuccessCases'
import { AboutUs } from '../../../components/AboutUs'
import { Divider } from '../../../components/Divider'
import { Statistics } from '../../../components/Statistics'
import { Slider } from '../../../components/Slider'
import { SectionTitle } from '../../../components/SectionTitle'
import { Layout } from '../../../components/layout'
import {
  MethodologySection,
  FeaturesSection,
} from '../../../components/SolutionsPages'

import banner1 from '../../../images/pages/controladoria/custos1.jpeg'
import banner2 from '../../../images/pages/controladoria/custos2.jpeg'
import {
  faTasks,
  faHandHoldingUsd,
  faLightbulb,
  faHeartbeat,
} from '@fortawesome/free-solid-svg-icons'

const slides = [
  {
    title: <>GESTÃO DE CUSTOS COMO PREMISSA PARA MELHORAR O RESULTADO</>,
    description: (
      <>
        Evidenciamos que o lucro pode melhorar ainda que não haja crescimento
        nas vendas
      </>
    ),
    backgroundUrl: banner1,
    sameOrigin: true,
    link: `#contato`,
    linkText: `Fale Conosco`,
  },
  {
    title: <>MAXIMIZE SEU RESULTADO IDENTIFICANDO OS PRODUTOS MAIS RENTÁVEIS</>,
    description: (
      <>
        Através de modelos confiáveis de apuração e monitoramento de custos para
        identificação de oportunidades de redução
      </>
    ),
    backgroundUrl: banner2,
    sameOrigin: true,
    link: `#contato`,
    linkText: `Fale Conosco`,
  },
]

const cases = [
  {
    title: `Diman Serviços Mecânicos`,
    text: `Implantação da rotina de gestão através da reunião mensal de análise
    de resultados. Realizadas mudanças na gestão através da classificação
    adequada de gastos, sistematização de relatórios e planejamento
    orçamentário.`,
  },
  {
    title: `TPC Law`,
    text: `Implantação da rotina de gestão para controles operacionais e
    tomada de decisões pautada em KPIs, possibilitando um crescimento
    seguro e sustentável.`,
  },
  {
    title: `Tecca Engenharia de Sistemas`,
    text: `Após o projeto foram fechados dois grandes negócios, resultado de uma
    maior assertividade na formação do preço de venda. Trabalho feito
    com foco nos custos diretos e créditos e débitos de impostos.`,
  },
]

const ApuracaoDeCustos: React.FC<PageProps> = ({ location }) => {
  const featuresItems = [
    {
      faIcon: faHandHoldingUsd,
      title: `Redução de riscos`,
      description: (
        <>
          Um processo de apuração de custos pouco confiável traz grandes riscos
          para o negócio. Estes riscos se apresentam de diversas formas:
          investir em produtos/serviços menos rentáveis, mascaram
          produtos/serviços que de fato poderiam trazer melhores resultados e
          induzem ao erro na formação do preço de venda. Melhorando a apuração
          de custos, diminuem-se estes riscos.
        </>
      ),
    },
    {
      faIcon: faTasks,
      title: `Boas práticas`,
      description: (
        <>
          Um sistema de custeio bem estruturado permite analisar os custos por
          processos, departamentos, unidades de negócio, produtos e serviços.
          Além disso, analisar indicadores de tendência que monitoram custos
          totais e unitários de cada processo e atividade, bem como definir
          melhores estratégias de precificação.
        </>
      ),
    },
    {
      faIcon: faLightbulb,
      title: `Soluções`,
      description: (
        <>
          Oferecemos soluções com foco na redução de custos e aumento do
          resultado por meio da estruturação de método de custeio padrão ou por
          absorção, determinação da margem de contribuição por produto/serviço,
          análise de ociosidade de mão-de-obra e equipamentos, formação do preço
          de venda, estrutura de centos de custos, custos fixos e variáveis e
          ponto de equilíbrio.
        </>
      ),
    },
    {
      faIcon: faHeartbeat,
      title: `Processo vital`,
      description: (
        <>
          A consultoria em gestão de custos está inserida em uma área vital para
          realizar um melhor planejamento financeiro e elevar a empresa para o
          próximo nível. Uma gestão de custos eficiente permite ao empresário
          identificar oportunidades de investimento, melhorar a margem de lucro,
          tomar decisões estratégicas mais assertivas e minimizar os riscos da
          operação.
        </>
      ),
    },
  ]

  const methodologyItems = [
    {
      count: 1,
      title: 'Base de dados confiáveis',
      description: (
        <>
          O primeiro passo para que se tenha um bom processo de apuração de
          custos é garantindo a confiabilidade dos dados: apontamentos de custos
          (produção, estoque, custos de ociosidade, ineficiência, perdas,
          devoluções, etc). Em seguida deve-se classificá-los em custos diretos,
          indiretos, fixos e variáveis, cada um em seu respectivo centro de
          custo.
        </>
      ),
    },
    {
      count: 2,
      title: 'Análise e resultados',
      description: (
        <>
          Após garantir a confiabilidade dos dados que estão sendo apontados,
          deve ser feita a compilação, análise e apresentação dos resultados
          periodicamente.
        </>
      ),
    },
    {
      count: 3,
      title: 'Tomada de decisão',
      description: (
        <>
          Apresentados os resultados periodicamente durante as reuniões de
          Conselho, é possível tomar decisões mais assertivas para que a
          organização se mantenha competitiva no mercado e cresça de forma
          sustentável.
        </>
      ),
    },
    {
      count: 4,
      title: 'Gestão da mudança',
      description: (
        <>
          Nossa metodologia equilibra teoria e prática. Estabelecemos planos de
          ação baseados na ferramenta 5W2H e utilizamos técnicas de gestão da
          mudança do Instituto HCMI para engajar e "puxar" as mudanças
          propostas, corrigindo os gaps identificados na análise de resultados e
          para que sejam alcançados os objetivos pretendidos.
        </>
      ),
    },
  ]

  return (
    <Layout location={location} mainFolder={location.pathname}>
      <SEO title="Controladoria - Apuraçāo de Custos - Valore Brasil" />
      <Slider slides={slides} />

      <section className={styles.solutions}>
        <div className="pt-3 pb-5">
          <div className="container">
            <SectionTitle
              title="Nosso Propósito"
              className="solutionsTitle"
              description="
              Propor soluções através do uso da Gestão de Custos para melhorar o
              resultado do seu negócio, trabalhando com boas práticas de mercado."
            />
          </div>
          <FeaturesSection items={featuresItems} />
        </div>

        <div className="container">
          <Divider />
        </div>

        <div className="py-5">
          <MethodologySection
            locationPath={location.pathname}
            items={methodologyItems}
            descripitionSection={`Conheça agora os 04 principais passos de nossa metodologia para
            estruturação do processo de apuração de custos.`}
          />
        </div>
      </section>

      <SuccessCases listCases={cases} isNumericCases={true} />
      <AboutUs />
      <Statistics />
    </Layout>
  )
}

export default ApuracaoDeCustos
